const images = require.context("./blogimages", true);
const imageList = images.keys().map((image) => images(image));

export function getRandomImagesForPage(page) {
  const imagesPerPage = 8;
  const startIndex = (page - 1) * imagesPerPage;
  const endIndex = startIndex + imagesPerPage;

  let imagesOnPage = [];

  if (startIndex < imageList.length) {
    imagesOnPage = imageList.slice(startIndex, endIndex);
  } else {
    const remainingImages = startIndex % imageList.length;
    imagesOnPage = imageList.slice(
      remainingImages,
      remainingImages + imagesPerPage
    );
  }

  while (imagesOnPage.length < imagesPerPage) {
    const remainingImages = imagesPerPage - imagesOnPage.length;
    imagesOnPage = imagesOnPage.concat(imageList.slice(0, remainingImages));
  }

  const shuffledImages = shuffleArray(imagesOnPage);

  return shuffledImages;
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
