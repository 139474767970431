import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../components/chat-channel/Layout";
import Container from "../components/chat-channel/Container";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import { RequestForm } from "../components/form";
import { useMedia } from "use-media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "react-accessible-accordion/dist/fancy-example.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { indexOf } from "ramda";
import { blogdata } from "../blogdata/data.js";
import { getRandomImagesForPage } from "../blogdata/image";

const headerImage = require("../blogdata/blogimages/hero_img.png");
const arrow_round = require("../assets/img/home_page_assist/left_arrow.svg");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Workativ Blog - Discover Insights and Tips for Workplace Optimization on the Workativ Blog"
        description="Explore the Workativ blog for valuable insights, tips, and trends in workflow automation, app integration, and conversational AI. Stay informed and empower your organization for enhanced workplace efficiency and positive outcomes."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left background-white pb-pt-40">
            <div className="container">
              {blogdata.slice(0, 1).map((data, index) => (
                <div key={index} className="blog-home-header-flex-container">
                  <div className="blog-home-header-flex-box-left">
                    <img src={headerImage} alt="Header" />
                  </div>
                  <div className="blog-home-header-flex-box-right">
                    <h3 className="font-name-big-normal-text mb-3">
                      {data.readtime}
                    </h3>
                    <h2 className="font-section-sub-header-automate mb-3">
                      {data.blogname}
                    </h2>
                    <p className="font-section-normal-text pb-0">
                      {data.description}
                    </p>

                    <button
                      onClick={() => (window.location.href = data.url)}
                      className="read-blog-button font-section-sub-header-smaller-blog cursor-pointer"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <BlogList />
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

const Pagination = ({ currentPage, itemsPerPage, searchTerm }) => {
  const filteredData = blogdata.filter((blog) =>
    blog.blogname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalItems = blogdata.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  let currentItems = [];
  let remainingItems = [];

  if (searchTerm) {
    currentItems = filteredData;
    remainingItems = blogdata.filter((blog) => !filteredData.includes(blog));
    currentItems = currentItems
      .concat(remainingItems)
      .slice(startIndex, endIndex);
  } else {
    currentItems = blogdata.slice(startIndex, endIndex);
  }

  return {
    currentItems,
    totalPages,
    totalItems,
  };
};
function BlogList() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { currentItems, totalPages, totalItems } = Pagination({
    currentPage,
    itemsPerPage: 8,
    searchTerm,
  });
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );
  const page = currentPage;
  const imagesForPage = getRandomImagesForPage(page);
  return (
    <section className="float-left w-100 blog-home-section">
      <div className="container">
        <div className="search-bar-div-blog">
          <FontAwesomeIcon
            icon={faSearch}
            className="search-icon-blog-home"
            rotation={90}
          />
          <input
            type="search"
            placeholder="Search"
            className="search-blog-names"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
          ></input>
        </div>
        <div className="flex-container-blog-home">
          {currentItems.map((data, index) => (
            <div key={index} className="flex-wrapper-blog-home">
              <div className="content-box-blog-home">
                <a href={data.url} className="blog-image-hover">
                  <img src={imagesForPage[index]} alt="" className="mb-30" />
                </a>
                {data.readtime ? (
                  <h4 className="font-section-small-text mb-18">
                    {data.readtime}
                  </h4>
                ) : null}

                <h3 className="font-section-normal-text-medium two-line-text">
                  <a href={data.url} className="color-black blog-name-hover">
                    {" "}
                    {data.blogname}{" "}
                  </a>
                </h3>
                <p className="font-section-small-text three-line-text mb-0 pb-0">
                  {data.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center align-items-center gap-15 mt-12">
          {currentPage > 1 && (
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              className="blog-home-button d-block align-self-center font-section-medium-text-small-pricing-right pt-3-5 cursor-pointer"
            >
              <img src={arrow_round} className="left-arrow-blog-home" />
            </button>
          )}

          {pageNumbers
            .slice(currentPage - 1, currentPage + 2)
            .map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber)}
                className={`blog-home-button d-block align-self-center font-section-medium-text-small-pricing-right cursor-pointer ${
                  currentPage === pageNumber ? "blog-home-active-button" : ""
                }`}
              >
                {pageNumber}
              </button>
            ))}

          {currentPage < pageNumbers.length && (
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              className="blog-home-button d-block align-self-center font-section-medium-text-small-pricing-right pt-3-5 cursor-pointer"
            >
              <img src={arrow_round} className="right-arrow-blog-home" />
            </button>
          )}
        </div>
      </div>
    </section>
  );
}
